import axios from 'axios'

const baseUrl = 'https://lobster-app-hqe3z.ondigitalocean.app'

const getData = async () => {
    try {
        const result = await axios.get(`${baseUrl}`)
        return result.data
    } catch (error) {
        console.log(error)
        return null
    }
}

export default {
    getData,
}