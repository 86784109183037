<template>
  <div class="home">
    <div class="container mt-5" v-if="loading">
      <div class="row">
        <div class="col-12 text-center">
          <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-2 mb-5" v-if="!loading">
      <div class="row">
        <div class="col-12 mt-4">
          <h4>Infos</h4>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-primary mb-3">
            <div class="card-header">Wallet Balance</div>
              <div class="card-body">
                <h5 class="card-title">${{ totalEquity.toFixed(2) }}</h5>
                <p class="card-text">Gesamtes Guthaben des Bots</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-primary mb-3">
            <div class="card-header">Profit</div>
              <div class="card-body">
                <h5 class="card-title">${{ getTotalProfit().toFixed(2) }}</h5>
                <p class="card-text">Totaler Profit des Bots</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-primary mb-3">
            <div class="card-header">Start</div>
              <div class="card-body">
                <h5 class="card-title">{{ new Date(parseInt(getFirstTrade())).toLocaleDateString("de-DE") }} {{ new Date(parseInt(getFirstTrade())).toLocaleTimeString("de-DE") }} </h5>
                <p class="card-text">Erster Trade des Bots</p>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <h4>Statistiken</h4>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Tag</div>
              <div class="card-body">
                <h5 class="card-title">${{ getAverageProfitDay().toFixed(2) }}</h5>
                <p class="card-text">Durschnittlicher Profit pro Tag</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Woche</div>
              <div class="card-body">
                <h5 class="card-title">${{ getAverageProfitWeek().toFixed(2) }}</h5>
                <p class="card-text">Durschnittlicher Profit pro Woche</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Monat</div>
              <div class="card-body">
                <h5 class="card-title">${{ getAverageProfitMonth().toFixed(2) }}</h5>
                <p class="card-text">Durschnittlicher Profit pro Monat</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Jahr</div>
              <div class="card-body">
                <h5 class="card-title">${{ getAverageProfitYear().toFixed(2) }}</h5>
                <p class="card-text">Durschnittlicher Profit pro Jahr</p>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <h4>Kosten</h4>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Server</div>
              <div class="card-body">
                <h5 class="card-title">${{ (109 * 3).toFixed(2) }}</h5>
                <p class="card-text">Kosten der Server im Monat</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Backend</div>
              <div class="card-body">
                <h5 class="card-title">${{ (25).toFixed(2) }}</h5>
                <p class="card-text">Backend Server Hosting</p>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="card text-bg-info mb-3">
            <div class="card-header">Frontend</div>
              <div class="card-body">
                <h5 class="card-title">${{ (5).toFixed(2) }}</h5>
                <p class="card-text">Frontend Server Hosting</p>
              </div>
          </div>
        </div>
        <div class="col-12 mt-4" v-if="activePositions.length > 0">
          <h4>Aktive Positionen</h4>
        </div>
        <div class="col-12 mt-2" v-if="activePositions.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Symbol</th>
                <th scope="col">Unrealisierter Profit</th>
                <th scope="col">Zeit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(position, positionIndex) in activePositions" :key="positionIndex">
                <th scope="row">{{ position.symbol }}</th>
                <td>{{ parseFloat(position.unrealizedPL).toFixed(2) }}</td>
                <td>{{ new Date(parseInt(position.cTime)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 mt-4" v-if="activeSpotPositions.length > 0">
          <h4>Aktive Spot Positionen</h4>
        </div>
        <div class="col-12 mt-2" v-if="activeSpotPositions.length > 0">
          <table class="table" v-for="(account, accountIndex) in activeSpotPositions" :key="`${accountIndex}-spot`">
            <thead>
              <tr>
                <th scope="col">Symbol</th>
                <th scope="col">Zeit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(unfilledOrder, orderIndex) in account.unfilledOrders" :key="`${orderIndex}-spot`">
                <th scope="row">{{ unfilledOrder.symbol }}</th>
                <td>{{ new Date(parseInt(unfilledOrder.cTime)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 mt-4">
          <h4>Accounts</h4>
        </div>
        <div class="col-12 mt-2" v-for="(account, index) in data" :key="index">
          <div class="accordion" :id="`accordion-${index}`">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-${index}`" aria-expanded="true" :aria-controls="`collapse-${index}`">
                  {{ account.name }}
                </button>
              </h2>
              <div :id="`collapse-${index}`" class="accordion-collapse collapse show" :data-bs-parent="`#accordion-${index}`">
                <div class="accordion-body">
                  <p>Profit: $<b>{{ getProfit(account.positions).toFixed(2) }}</b></p>
                  <p>Kosten: $<b>109</b> / Monat</p>

                  <p  v-if="account.activePositions.length > 0">Aktive Positionen:</p>

                  <table class="table" v-if="account.activePositions.length > 0">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Unrealisierter Profit</th>
                        <th scope="col">Zeit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(position, positionIndex) in account.activePositions" :key="positionIndex">
                        <th scope="row">{{ position.symbol }}</th>
                        <td>{{ parseFloat(position.unrealizedPL).toFixed(2) }}</td>
                        <td>{{ new Date(parseInt(position.cTime)).toLocaleDateString('de-DE') }} {{ new Date(parseInt(position.ctime)).toLocaleTimeString('de-DE') }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Zeit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(position, positionIndex) in account.positions" :key="positionIndex">
                        <th scope="row">{{ position.symbol }}</th>
                        <td>{{ parseFloat(position.netProfit).toFixed(2) }}</td>
                        <td>{{ new Date(parseInt(position.ctime)).toLocaleDateString('de-DE') }} {{ new Date(parseInt(position.ctime)).toLocaleTimeString('de-DE') }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2" v-for="(account, index) in spotData" :key="`${index}-spot`">
          <div class="accordion" :id="`accordion-${index}-spot`">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-${index}-spot`" aria-expanded="true" :aria-controls="`collapse-${index}-spot`">
                  {{ account.name }}
                </button>
              </h2>
              <div :id="`collapse-${index}-spot`" class="accordion-collapse collapse show" :data-bs-parent="`#accordion-${index}-spot`">
                <div class="accordion-body">
                  <p>Kosten: $<b>84</b> / Monat</p>
                  <p>Profit: $<b>{{ getProfitSpot(account).toFixed(2) }}</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcebotService from '@/services/icebotService.js'

export default {
  name: 'HomeView',
  data: () => {
    return {
      loading: true,
      data: null,
      spotData: null,
      activePositions: [],
      activeSpotPositions: [],
      totalEquity: 0.0,
    }
  },
  methods: {
    initializeView: async function() {
      this.loading = true
      const apiData = await IcebotService.getData()
      this.data = apiData.futures
      this.spotData = apiData.spot
      this.totalEquity = apiData.totalEquity

      console.log(this.data)
      console.log(this.spotData)

      if (this.data != null) {
        for (const account of this.data) {
          for (const position of account.activePositions) {
            this.activePositions.push(position)
          }
        }
        this.loading = false
      }

      if (this.spotData != null) {
        for (const account of this.spotData) {
          if (account.unfilledOrders.length > 0) {
            this.activeSpotPositions.push(account)
          }
        }
        this.loading = false
      }
    },
    getProfit: function(positions) {
      let counter = 0.0

      for (const position of positions) {
        counter = counter + parseFloat(position.netProfit)
      }

      return counter
    },
    getProfitSpot: function(account) {
      if (account.unfilledOrders.length > 0) {
          return 0
        }

      return account.currentBalance - account.initialBalance
    },
    getTotalProfit: function() {
      let counter = 0.0
      if (this.data != null) {
        for (const account of this.data) {
          for (const position of account.positions) {
            counter = counter + parseFloat(position.netProfit)
          }
        }
      }

      counter = counter + this.getTotalProfitSpot()
      return counter
    },
    getTotalProfitSpot: function() {
      if (this.spotData != null) {
        for (const account of this.spotData) {
          if (account.unfilledOrders.length > 0) {
            return 0
          }

          return account.currentBalance - account.initialBalance
        }
      }
      return 0.0
    },
    getFirstTrade: function() {
      let firstTrade = null
      if (this.data != null) {
        for (const account of this.data) {
          for (const position of account.positions) {
            const timestamp = position.ctime
            if (firstTrade == null) {
              firstTrade = timestamp
            }

            if (timestamp < firstTrade) {
              firstTrade = timestamp
            }
          }
        }
      }
      return parseInt(firstTrade)
    },
    getAverageProfitDay: function() {
      const totalProfit = this.getTotalProfit()
      const firstTrade = this.getFirstTrade()

      const timeDiff = new Date().getTime() - firstTrade
      const days = timeDiff / 1000 / 60 / 60 / 24
    
      return totalProfit / days
    },
    getAverageProfitWeek: function() {
      const totalProfit = this.getTotalProfit()
      const firstTrade = this.getFirstTrade()

      const timeDiff = new Date().getTime() - firstTrade
      const weeks = timeDiff / 1000 / 60 / 60 / 24 / 7
    
      return totalProfit / weeks
    },
    getAverageProfitMonth: function() {
      const totalProfit = this.getTotalProfit()
      const firstTrade = this.getFirstTrade()

      const timeDiff = new Date().getTime() - firstTrade
      const months = timeDiff / 1000 / 60 / 60 / 24 / 30
    
      return totalProfit / months
    },
    getAverageProfitYear: function() {
      const totalProfit = this.getTotalProfit()
      const firstTrade = this.getFirstTrade()

      const timeDiff = new Date().getTime() - firstTrade
      const years = timeDiff / 1000 / 60 / 60 / 24 / 365
    
      return totalProfit / years
    }
  },
  mounted: function() {
    this.initializeView()
  }
}
</script>
